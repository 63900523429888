<template>
    <div v-if="this.pageData" class="steering-comitee">
        
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" menuparent="who we are">            
        </HeaderIntroImage>

        <main>
            <section class="main-content">
                <div class="container">
                    <div class="box">
                        <p v-html="this.pageData.attributes.field_description_w_summary.value">
                        </p>
                        <div class="attachments-container">
                        <attachments v-if="pageData" :attachmentData="pageData"></attachments>
                    </div>
                    </div>
                </div>

                <div class="list" v-if="this.personData">
                    <div class="container">

                        <h2>Members of the Steering Committee</h2>
                        <div class="people-card">
                            <div v-for="(person, index) in this.personData" :key="'person'+index" :id="hashid(person)">
                                <div class="picture">
                                    <div>
                                        <div>
                                            <img :src="personphoto(person)" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h2 class="name">
                                        {{ person.attributes.field_first_name + ' ' + person.attributes.field_last_name }}
                                    </h2>
                                    <h3 class="role" v-if="person.attributes.field_person_position">
                                            {{ person.attributes.field_person_position }}
                                        </h3>
                                    <p class="description" ref="description" v-html="person.attributes.field_biography.value">
                                     </p>
                                    <a class="read-more accordion" @click="toggleItem(index)">
                                        Read more
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </main>

    </div>
</template>

<script>
import Attachments from '../components/Attachments.vue'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import { fetchNodes, fetchSinglePage } from '../libs/drupalClient'
    export default {
        name: 'steering-committee',
        components: {
            HeaderIntroImage,
            Attachments
        },
        data: () => {
            return {
                pageData: null,
                currentPageID: "ca795f14-cce3-4cc8-b628-310b5fc4c639",
                personData: null
            }
        },
        computed: {
            hash() {
                return this.$route.hash
            }
        },
        methods: {
            toggleItem(index) {
                this.$refs.description[index].classList.toggle("show") 
            },
            logourl(index) {
                return process.env.VUE_APP_ENDPOINT + this.pageData.field_page_images[index].attributes.uri.url
            },
            personphoto(node) {
                var f = this.personData.filter(p => p.attributes.drupal_internal__nid == node.attributes
                    .drupal_internal__nid)
                if (f[0].field_photo)
                    return process.env.VUE_APP_ENDPOINT + f[0].field_photo.attributes.uri.url
                else
                    return ''
            },
            hashid(p) {
                var s = p.attributes.field_first_name + p.attributes.field_last_name
                s = s.replace(/\s/g, '')
                s = s.replace(/\./g, '')
                return s
            }
        },
        async mounted() {
            await fetchSinglePage(this.currentPageID, {
                include: ['field_page_banner', 'field_page_images', 'field_ref_ui_text','field_attachments']
            })
            .then(res => {
                this.pageData = res
                //console.log('pagedata', res)
            })

            await fetchNodes('person', {
                include: ['field_photo']
            }).then(res => {
                this.personData = res
                    .filter(p => p.attributes.field_steering_commitee == true)
                    .sort((a,b) => { return a.attributes.field_last_name >= b.attributes.field_last_name ? 1 : -1 })
            })

            await setTimeout(() => {
                if (this.hash && document.querySelector(this.hash)) {
                    window.scrollBy({
                        top: document.querySelector(this.hash).getBoundingClientRect().top - 180,
                        behavior: 'smooth'
                    })
                }
            }, 200);
        },
        watch: {
            async hash() {
                await setTimeout(() => {
                    if (this.hash && document.querySelector(this.hash)) {
                        window.scrollBy({
                            top: document.querySelector(this.hash).getBoundingClientRect().top - 180,
                            behavior: 'smooth'
                        })
                    }
                }, 200);
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

div.item {
    margin-top: -3rem;
    margin-bottom: 5rem;
    position: relative;
    z-index: 3;
}

.steering-comitee main {
    background-color: #1B1D3C;

    .main-content .box {
        padding: 1rem 1rem;
        margin-top: -2rem;
        z-index: 5;
        position: relative;
        border-radius: 5px;
        background-color: #fff;
        p { max-width: 1000px; }
    }

    .data { display: none; }
}

.list {
    margin: 3rem 0rem;

    .container > h2 {
        color: $orange;
        text-align: center;
        font-weight: 300;
        font-size: 1.5rem;
        margin: 2rem 0rem;
    }
}

.people-card > div { margin-bottom: 5rem; }


@media (min-width:768px) {
    .steering-comitee main .main-content .box { padding: 3rem 4rem; }
    .steering-comitee main .main-content .box { margin-top: -4rem; }

    .list {
        .container>h2 {
            font-size: 1.875rem;
            margin-bottom: 3rem;
        }
    }
}

@media (min-width:1024px) {
    .people-card {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        > div { width: 40%; }
    }

    .list {
        .container > h2 {
            font-size: 2.5rem;
            margin-bottom: 5rem;
        }
    }
}

@media (min-width:1024px) {
    .people-card > div {
        width: 45%;
        display: flex;        
        .picture { margin-right: 20px; }
    }
}
</style>